
import { Layout, SideInfo } from '@components/common'
import { useClientSWR, useT } from '@utils'
import { useRouter } from 'next/router'
import * as server from '@lib/server'
import { useState } from 'react'
import cn from 'classnames'
import {
    BarChart, Bar,
    XAxis,
} from 'recharts';
import Link from 'next/link'
import Image from 'next/image'

const dishOrders = [
    {
        name: "Pokémon Holiday: Pikachu Figure by Funko",
        image: {
            imageUrl: "/test/1.jpg",
            thumbnailUrl: "/test/1.jpg",
            tags: "#images"
        },
        quantity: 5
    },

    {
        name: "Pokémon Holiday: Pikachu Figure by Funko",
        image: {
            imageUrl: "/test/2.jpg",
            thumbnailUrl: "/test/2.jpg",
            tags: "#images"
        },
        quantity: 5
    },

    {
        name: "Pokémon Holiday: Pikachu Figure by Funko",
        image: {
            imageUrl: "/test/3.jpg",
            thumbnailUrl: "/test/3.jpg",
            tags: "#images"
        },
        quantity: 5
    }
]

export default function Home({ }) {
    const t = useT()
    const router = useRouter()

    const [tabIndex, setTabIndex] = useState(0)

    const { data, error } = useClientSWR('/ordersAll', async () => {
        return await server.getOrders(null)
    })
    if (!data) return <div />

    const cdata = data;
    const orders = cdata.filter(order => order.transactionStatus == 'paid')
    const chart = chartData(tabIndex, orders)

    const myDate = new Date();

    const allOrderTotal = orders.reduce((accumulator, order) => {
        accumulator += (order.total ?? 0);
        return accumulator;
    }, 0)

    return (
        <div className='flex flex-col'>
            <div className='h-1' />
            <div className='tabs self-center'>
                {
                    [t('Today'), t('7 days'), t('30 days')].map((item, index) => {
                        return <a key={index} className={cn("tab", { "tab-active text-color33 tab-bordered border-color33": tabIndex == index }, { "text-color99": tabIndex != index })} onClick={() => setTabIndex(index)}>{item}</a>
                    })
                }
            </div>
            <div className='flex flex-col p-2'>
                <div className='text-xs text-color99 px-2'>
                    {t('Total Orders')}
                </div>
                <div className='font-bold px-2 pb-2'>
                    {(chart.totalMoney / 100).toFixed(2)}
                </div>

                <BarChart width={screen.width - 10} height={200} data={chart.data}>
                    <Bar dataKey="orders" fill="#8884d8" />
                    <XAxis type="category" dataKey="time" />
                </BarChart>
            </div>
            <div className='bg-colore5 h-px' />
            <div className="flex flex-col  font-bold">
                <div className='p-2'>{myDate.toLocaleString()}</div>
                <div className="flex flex-row justify-between items-center p-4 bg-white mt-4">
                    <div className="flex flex-col justify-start w-1/3">
                        <div className='text-color66 text-xxs'>{t('All Sales')}</div>
                        <div className='text-2xl font-bold'>${(allOrderTotal / 100).toFixed(2)}</div>
                    </div>
                    <div className="flex flex-col justify-start w-1/3">
                        <div className='text-color66 text-xxs'>{t('All Orders')}</div>
                        <div className='text-2xl font-bold'>{orders.length}</div>
                    </div>
                    <Link href={'/'}>
                        <a className='text-colorlink text-xs'>
                            {t('More Information >')}
                        </a>
                    </Link>
                </div>

                {/* <div className="flex flex-row justify-between items-center p-4 bg-white mt-2">
                    <div className="flex flex-col justify-start w-1/3 ">
                        <div className='text-color66 text-xxs'>{t('All Views')}</div>
                        <div className='text-2xl font-bold'>{129}</div>
                    </div>
                    <div className="flex flex-col justify-start w-1/3">
                        <div className='text-color66 text-xxs'>{t('Online')}</div>
                        <div className='text-2xl font-bold'>{232}</div>
                    </div>
                    <Link href={'/'}>
                        <a className='text-colorlink text-xs'>
                            {t('More Information >')}
                        </a>
                    </Link>

                </div>

                <div className="flex flex-row justify-between items-center p-4 bg-white mt-2">
                    <div className="flex flex-col justify-start w-1/3 ">
                        <div className='text-color66 text-xxs'>{t('Orders to be delivery')}</div>
                        <div className='text-2xl font-bold'>{39}</div>
                    </div>
                    <div className="flex flex-col justify-start w-1/3">
                        <div className='text-color66 text-xxs'>{t('Order delivered')}</div>
                        <div className='text-2xl font-bold'>{232}</div>
                    </div>
                    <Link href={'/'}>
                        <a className='text-colorlink text-xs'>
                            {t('More Information >')}
                        </a>
                    </Link>

                </div>

                <div className="flex flex-col p-4 bg-white mt-2">
                    <div className='text-base font-bold'>{t('TOP3 viewed in the past 14 days')}</div>
                    <div className='text-color66 text-sm'>{t('Products that get more views are more likely to be purchased.')}</div>

                    <div className="flex flex-row justify-between mt-8 text-color66 text-sm font-bold">
                        <div>{t('Products')}</div>
                        <div>{t('5月13日~5月27日')}</div>
                    </div>

                    {dishOrders && dishOrders.map((item, index) => {
                        return (
                            <div className='flex flex-row justify-between items-center w-full text-sm' key={index}>
                                <div className='flex flex-row justify-start items-center p-2'>
                                    <div className='w-12 h-12 relative' >
                                        <Image src={item.image.thumbnailUrl} layout='fill' objectFit='cover' />
                                    </div>
                                    <div className='overflow-ellipsis box-content line-clamp-2 text-sm ml-2 flex-1 text-colorlink'>{item.name}</div>
                                </div>
                                <div className='w-1/4 flex flex-row justify-end items-center text-color66'>{item.quantity + ' times'}</div>

                            </div>
                        );
                    })}
                </div>

                <div className="flex flex-col p-4 bg-white mt-2">
                    <div className='text-base font-bold'>{t('TOP3 added to cart in the past 14 days')}</div>
                    <div className='text-color66 text-sm'>{t('Products that get more views are more likely to be purchased.')}</div>

                    <div className="flex flex-row justify-between mt-8 text-color66 text-sm font-bold">
                        <div>{t('Products')}</div>
                        <div>{t('5月13日~5月27日')}</div>
                    </div>

                    {dishOrders && dishOrders.map((item, index) => {
                        return (
                            <div className='flex flex-row justify-between items-center w-full text-sm' key={index}>
                                <div className='flex flex-row justify-start items-center p-2'>
                                    <div className='w-12 h-12 relative' >
                                        <Image src={item.image.thumbnailUrl} layout='fill' objectFit='cover' />
                                    </div>
                                    <div className='overflow-ellipsis box-content line-clamp-2 text-sm ml-2 flex-1 text-colorlink'>{item.name}</div>
                                </div>
                                <div className='w-1/4 flex flex-row justify-end items-center text-color66'>{item.quantity + ' times'}</div>

                            </div>
                        );
                    })}
                </div>

                <div className="flex flex-col p-4 bg-white mt-8">
                    <div className='flex flex-row flex-1 justify-between mt-2 font-bold'>
                        <span>{t("New Products")}</span>
                        <span className='w-5 h-5 rounded-full bg-cyan text-white text-xs items-center py-0.5 px-1'>{t("LL")}</span>
                    </div>

                    <div className='flex flex-row items-center mt-2'>
                        <div className='w-12 h-12 relative' >
                            <Image src={dishOrders[0].image.thumbnailUrl} layout='fill' objectFit='cover' />
                        </div>
                        <div className='overflow-ellipsis box-content line-clamp-2 text-sm ml-2 flex-1 text-colorlink'>{'金沙包'}</div>
                    </div>

                    <div className='text-color66 text-sm mt-4'>{myDate.toLocaleString()}</div>
                </div>

                <div className="flex flex-col p-4 bg-white mt-2">
                    <div className='flex flex-row flex-1 justify-between mt-2 font-bold'>
                        <span>{t("New Category")}</span>
                        <span className='w-5 h-5 rounded-full bg-cyan text-white text-xs items-center py-0.5 px-1'>{t("LL")}</span>
                    </div>

                    <div className='flex flex-row items-center mt-2'>
                        <div className='w-12 h-12 relative' >
                            <Image src={dishOrders[0].image.thumbnailUrl} layout='fill' objectFit='cover' />
                        </div>
                        <div className='overflow-ellipsis box-content line-clamp-2 text-sm ml-2 flex-1 text-colorlink'>{'Vegetable'}</div>
                    </div>

                    <div className='text-color66 text-sm mt-4'>{myDate.toLocaleString()}</div>
                </div>

                <div className="flex flex-col p-4 bg-white mt-2">
                    <div className='flex flex-row flex-1 justify-between mt-2 font-bold'>
                        <span>{t("New Administrator")}</span>
                        <span className='w-5 h-5 rounded-full bg-cyan text-white text-xs items-center py-0.5 px-1'>{t("LL")}</span>
                    </div>

                    <div className='flex flex-row items-center mt-2'>
                        <div className='w-12 h-12 relative' >
                            <Image src={'/test/pokemon.jpg'} layout='fill' objectFit='cover' />
                        </div>
                        <div className='overflow-ellipsis box-content line-clamp-2 text-sm ml-2 flex-1 text-colorlink'>{'Hello Li'}</div>
                    </div>

                    <div className='text-color66 text-sm mt-4'>{myDate.toLocaleString()}</div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        throw new Error("Sentry Frontend Error");
                    }}
                >
                    Throw error
                </button> */}
            </div>
            {/* <SideInfo /> */}
        </div>

    )
}

const chartData = (index: number, orders) => {
    if (index == 0) {
        let midNightToday = new Date()
        midNightToday.setHours(0, 0, 0, 0)
        const todayOrders = orders.filter((item, index) => {
            const createdDate = new Date(item.createdAt)
            return createdDate > midNightToday
        })
        let data = todayOrders.reduce((accumulator, order) => {
            const createdDate = new Date(order.createdAt)
            const hour = createdDate.toLocaleString('en-US', { hour: 'numeric', hour12: true })
            const findItem = accumulator.find(
                (item) => item['time'] == hour,
            );
            if (!findItem) {
                accumulator.push({
                    time: hour,
                    orders: 1,
                });
            } else {
                findItem['orders'] += 1;
            }
            return accumulator
        }, [])
        if (todayOrders.length == 0) {
            data = [{
                time: "3 AM",
                orders: 0
            }, {
                time: "9 AM",
                orders: 0
            }, {
                time: "3 PM",
                orders: 0
            }, {
                time: "9 PM",
                orders: 0
            }]
        }

        const totalMoney = todayOrders.reduce((accumulator, order) => {
            accumulator += (order.total ?? 0);
            return accumulator;
        }, 0);

        return {
            totalMoney,
            total: todayOrders.length,
            data: data
        }
    }

    const days = index == 1 ? 7 : 30;
    let backDate = new Date()
    backDate.setDate(backDate.getDate() - days);
    const daysOrders = orders.filter((item, index) => {
        const createdDate = new Date(item.createdAt)
        return createdDate > backDate
    })

    const data = daysOrders.reduce((accumulator, order) => {
        const createdDate = new Date(order.createdAt)
        const day = getFormattedDate(createdDate)
        const findItem = accumulator.find(
            (item) => item['time'] == day,
        );
        if (!findItem) {
            accumulator.push({
                time: day,
                orders: 1,
            });
        } else {
            findItem['orders'] += 1;
        }
        return accumulator
    }, [])

    const totalMoney = daysOrders.reduce((accumulator, order) => {
        accumulator += (order.total ?? 0);
        return accumulator;
    }, 0);

    return {
        totalMoney,
        total: daysOrders.length,
        data: data
    }
}

function getFormattedDate(date) {
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day;
}

Home.Layout = Layout
